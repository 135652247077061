<template>
<transition name="fadePage" mode="out-in">
<div class="assetDetails PurchasingAssetDetails">
  <Top-Nav></Top-Nav>
  <div class="common_title" v-if="isType==1">{{$t('menu.zcxq_star')}}</div>
  <div class="common_title" v-if="isType==2">{{$t('menu.zcxq_zeed')}}</div>
  <div class="node_table" :style="boxHeightObj">
    <div class="list_wrapper" :style="boxHeightObj">
      <div class="list" v-for="(item,index) in listData" :key="index">
        <div>
          <div class="title_a">{{formatTypesMethod(item.types)}}</div>
          <div class="time_a">{{formatDateTimeMethod(item.time)}}</div>
        </div>
        <div class="num_a" v-if="item.types < 6">+ {{formatAmountMethod(item.amount)}}</div>
        <div class="num_a" v-if="item.types == 6">+ {{formatAmountMethod(item.amount2)}}</div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>
<script>
import '../styles/common.css';
import '../../../../style/assetDetails.css';
import TopNav from '../common/PurchasingTop.vue';

const poolTwo = require('../../../../utils/poolTwo')

export default {
  name: 'PurchasingAssetDetails',
  components:{TopNav},
  data() {
    return {
      formatAmountMethod:poolTwo.amountConversion,
      formatDateTimeMethod:poolTwo.getTime,
      formatTypesMethod:this.formatTypes,
      windowHeight: window.innerHeight,   //屏幕高度
      boxHeightObj:{  //背景框的高度
        height:0
      }, 
      isType:1,//1为STAR总资产，2为ZEED总资产 3YEED总资产
      listData:[
      //   {
      //   name:"算力点",
      //   date:"2022-05-11  20:19:42",
      //   num:"+1200"
      // },{
      //   name:"算力点",
      //   date:"2022-05-11  20:19:42",
      //   num:"+1200"
      // }
      ]
    }
  },
  mounted(){
    this.isType = this.$route.params.type;
    this.boxHeightObj.height = (this.windowHeight/10)-5-7.8-3.2+'rem';

    let that = this
    let myAddress = localStorage.getItem('address')
    if(!myAddress || typeof myAddress == "undefined" || myAddress == 0){
      //console.log('没有钱包地址不获取数据',myAddress)
      return 
    }

    // 获取数据
    let types_in = '';
    if(this.isType==1){
      types_in = '[1,5]';
    }else{
      types_in = '[4]';
    }

    let query = `query MyQuery {
        withdrawModels(
          orderBy: time
          orderDirection: desc
          where: {types_in: `+types_in+`, account: "`+myAddress+`"}
        ) {
          amount
          amount2
          time
          types
        }
      }`
    // 挖出流水
    // types 1. 提现节点奖励
    // types 2. 节点质押， 3解除质押
    // type 4. 推荐奖励提现
    // type 5. 算力挖矿奖励
    // type 6. 质押
    // 提现类型的操作，amount是token，amount2是token的usdt价值
    // type=6质押的时候，amount是usdt数量，amount2是seed数量
    poolTwo.graphql(query).then(res=>{
      console.log('withdrawModels',res)
      let withdrawModels = res.data.withdrawModels
      that.listData = withdrawModels
    })

  },
  methods:{
    formatTypes(type){
     // return type
      switch (type) {
        case 1:
          return this.$t('menu.cashWitNodeReward')  //提现节点奖励
        case 2:
          return this.$t('menu.nodePledge') //节点质押
        case 3:
          return this.$t('menu.releasePledge') //解除质押
        case 4:
          return this.$t('menu.recommendedReWith') //推荐奖励提现
        case 5:
          return this.$t('menu.computingPowerReward') //算力挖矿奖励
        case 6:
          return this.$t('menu.pledge') //质押
      }
    }
  }
}
</script>
<style scoped>
</style>